<template>
  <b-card>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 316.42px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image32.png')"
          style="width: 709.80px; height: 316.42px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c18">
      <span class="c4">Pertama-tama setelah kita berhasil melakukan login, maka user akan diarahkan ke menu halaman beranda Disposisi</span>
    </p>
    <ol
      class="c6 lst-kix_list_7-1 start"
      start="1"
    >
      <li class="c14 li-bullet-1">
        <h2
          id="h.30j0zll"
          style="display:inline"
        >
          <span class="c1">Tambah Disposisi (Staff SE).</span>
        </h2>
      </li>
    </ol>
    <p class="c0">
      <span class="c4" />
    </p>
    <p class="c18 c13">
      <span class="c4" />
    </p>
    <a id="t.3c9232b2d5d9ea2a259f8403ad80b64e9db35916" /><a id="t.0" />
    <table class="c23">
      <tr class="c25">
        <td
          class="c29"
          colspan="1"
          rowspan="1"
        >
          <p class="c18">
            <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 152.00px; height: 330.00px;">
              <b-img
                alt=""
                :src="require('@/assets/images/images-1/image34.png')"
                style="width: 152.00px; height: 330.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""
              />
            </span>
          </p>
        </td>
      </tr>
    </table>
    <p class="c26 c13">
      <span class="c4" />
    </p>
    <p class="c26 c13 c57">
      <span class="c4" />
    </p>
    <p class="c26 c12">
      <span class="c4">Untuk mengakses menu tambah disposisi, user harus memilih menu tambah disposisi yang ada pada bilah sidebar yang berada pada sisi kiri layar, lalu user akan diarahkan ke form untuk mengisi semua informasi yang dibutuuhkan untuk disposisi seperti yang ditampilkan pada gambar dibawah ini:</span>
    </p>
    <p class="c26 c35">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 710.99px; height: 329.95px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image33.png')"
          style="width: 710.99px; height: 329.95px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c26 c13 c35">
      <span class="c4" />
    </p>
    <p class="c26 c13 c35">
      <span class="c4" />
    </p>
    <p class="c26 c12">
      <span class="c4">Lalu setelah semua informasi yang diperlukan untuk disposisi sudah lengkap maka scroll mouse dan arahkan kearah kiri bawah pada form tambah disposisi untuk menyimpan disposisi.</span>
    </p>
    <p class="c5 c13">
      <span class="c4" />
    </p>
    <p class="c18">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 712.24px; height: 329.95px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image36.png')"
          style="width: 712.24px; height: 329.95px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c3">
      <span class="c4"><br></span>
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c18 c13">
      <span class="c4" />
    </p>
    <p class="c3">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_7-1"
      start="2"
    >
      <li class="c14 li-bullet-1">
        <h2
          id="h.1fob9te"
          style="display:inline"
        >
          <span class="c1">Daftar Disposisi (Pihak Terkait Disposisi).</span>
        </h2>
      </li>
    </ol>
    <p class="c0">
      <span class="c11 c8" />
    </p>
    <p class="c18 c13 c40">
      <span class="c11 c8" />
    </p>
    <a id="t.7187b0cb3a0e26037bc37332a736184de77aa725" /><a id="t.1" />
    <table class="c23">
      <tr class="c25">
        <td
          class="c36"
          colspan="1"
          rowspan="1"
        >
          <p class="c18">
            <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 152.00px; height: 330.00px;">
              <b-img
                alt=""
                :src="require('@/assets/images/images-1/image35.png')"
                style="width: 152.00px; height: 330.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""
              />
            </span>
          </p>
        </td>
        <td
          class="c37"
          colspan="1"
          rowspan="1"
        >
          <p class="c18">
            <span class="c16">Pilih </span><span class="c8 c38">Proses Disposisi</span><span class="c16">&nbsp;atau </span><span class="c38 c8">Disposisi Selesai</span>
          </p>
        </td>
        <td
          class="c36"
          colspan="1"
          rowspan="1"
        >
          <p class="c18">
            <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 152.00px; height: 330.00px;">
              <b-img
                alt=""
                :src="require('@/assets/images/images-1/image40.png')"
                style="width: 152.00px; height: 330.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""
              />
            </span>
          </p>
        </td>
      </tr>
    </table>
    <p class="c18 c13">
      <span class="c4" />
    </p>
    <p class="c26 c40">
      <span class="c4">Untuk melihat daftar disposisi yang telah dibuat sebelumnya, user bisa mengaksesnya dengan memilih menu proses disposisi atau disposisi selesai dimana user akan di arahkan kepada detail disposisi berdasarkan pada status disposisi dimana status </span><span class="c11 c8">open</span><span class="c4">&nbsp;adalah </span><span class="c11 c8">proses disposisi</span><span class="c4">&nbsp;dan status </span><span class="c11 c8">close</span><span class="c4">&nbsp;adalah </span><span class="c11 c8">disposisi selesai</span><span class="c4">. setelah salah satu menu tersebut dipilih maka akan muncul tampilan seperti berikut dibawah:</span>
    </p>
    <p class="c0">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.75px; height: 329.95px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image39.png')"
          style="width: 709.75px; height: 380.76px; margin-left: -0.00px; margin-top: -50.80px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c18 c13">
      <span class="c4" />
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c18 c13">
      <span class="c11 c8" />
    </p>
    <ol
      class="c6 lst-kix_list_7-1"
      start="3"
    >
      <li class="c14 li-bullet-2">
        <h2
          id="h.3znysh7"
          style="display:inline"
        >
          <span class="c1">Detail Disposisi (Pihak Terkait Disposisi).</span>
        </h2>
      </li>
    </ol>
    <p class="c0">
      <span class="c11 c8" />
    </p>
    <p class="c26 c40">
      <span class="c4">Setelah kita melewati tahapan ke-2 diatas yaitu melihat daftar disposisi baik </span><span class="c11 c8">Proses Disposisi </span><span class="c4">maupun </span><span class="c11 c8">Disposisi Selesai</span><span class="c4">&nbsp;maka kita bisa memilih surat disposisi mana yang akan dilihat detail suratnya dengan meng-klik pada bagian kolom </span><span class="c8 c11">No.Disposisi </span><span class="c4">yaitu</span><span class="c11 c8">&nbsp;</span><span class="c4">pada nomor disposisi yang digaris bawahi yang ingin dipilih.</span>
    </p>
    <p class="c18 c13 c40">
      <span class="c4" />
    </p>
    <a id="t.3c9232b2d5d9ea2a259f8403ad80b64e9db35916" /><a id="t.2" />
    <table class="c23">
      <tr class="c25">
        <td
          class="c60"
          colspan="1"
          rowspan="1"
        >
          <p class="c18">
            <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 692.00px; height: 330.00px;">
              <b-img
                alt=""
                :src="require('@/assets/images/images-1/image45.png')"
                style="width: 692.00px; height: 330.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""
              />
            </span>
          </p>
        </td>
      </tr>
    </table>
    <p class="c26 c13">
      <span class="c4" />
    </p>
    <p class="c26 c13">
      <span class="c4" />
    </p>
    <p class="c26 c12">
      <span class="c4">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Setelah pada kotak merah tersebut di-klik, maka pengguna akan dirahkan ke menu </span><span class="c11 c8">Detail Disposisi</span><span class="c4">, pada menu tersebut kita bisa melihat detail dari disposisi yang sudah ditambahkan sebelumnya.</span>
    </p>
    <p class="c26 c13 c12">
      <span class="c4" />
    </p>
    <p class="c13 c12 c26">
      <span class="c4" />
    </p>
    <p class="c51">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 365.19px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image43.png')"
          style="width: 709.80px; height: 365.19px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c51">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 277.18px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image49.png')"
          style="width: 709.80px; height: 277.18px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c26 c13">
      <span class="c4" />
    </p>
    <p class="c5">
      <span class="c4">Pada menu ini juga user bisa melihat file disposisi yang telah diupload sebelumnya, juga jika sebelumnya user merupakan pihak yang disebut atau terkait dengan disposisi yaitu untuk </span><span class="c11 c8">keputusan</span><span class="c4">&nbsp;dan </span><span class="c11 c8">responder</span><span class="c4">&nbsp;maka user tersebut bisa menambahkan komentar pada detail disposisi seperti yang akan dijelaskan tahap selanjutnya.</span>
    </p>
    <p class="c13 c18">
      <span class="c1" />
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c2">
      <span class="c1" />
    </p>
    <ol
      class="c6 lst-kix_list_7-1"
      start="4"
    >
      <li class="c14 li-bullet-1">
        <h2
          id="h.2et92p0"
          style="display:inline"
        >
          <span class="c1">Edit Disposisi (Staff SE).</span>
        </h2>
      </li>
    </ol>
    <p class="c2">
      <span class="c1" />
    </p>
    <p class="c19 c12">
      <span class="c31">Jika Diperlukan perngubahan data pada dokumen disposisi, user bisa mengakses menu edit disposisi melalui daftar disposisi baik </span><span class="c8 c31">Proses Disposisi</span><span class="c31">&nbsp;atau </span><span class="c31 c8">Disposisi Selesai</span><span class="c31">, maupun melalui </span><span class="c31 c8">Detail Disposisi</span><span class="c31 c34">, dengan langkah-langkah sebagai berikut.</span>
    </p>
    <p class="c2 c12">
      <span class="c34 c31" />
    </p>
    <ol
      class="c6 lst-kix_list_7-2 start"
      start="1"
    >
      <li class="c14 li-bullet-1">
        <h3
          id="h.tyjcwt"
          style="display:inline"
        >
          <span class="c1">Akses Menu Edit Melalui Daftar Disposisi (Staff SE).</span>
        </h3>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <a id="t.3c9232b2d5d9ea2a259f8403ad80b64e9db35916" /><a id="t.3" />
    <table class="c23">
      <tr class="c25">
        <td
          class="c58"
          colspan="1"
          rowspan="1"
        >
          <p class="c17">
            <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 710.00px; height: 154.00px;">
              <b-img
                alt=""
                :src="require('@/assets/images/images-1/image46.png')"
                style="width: 710.00px; height: 154.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""
              />
            </span>
          </p>
        </td>
      </tr>
    </table>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c19 c24">
      <span class="c4">Edit melalui Daftar Disposisi bisa dilakukan dengan langkah-langkah:</span>
    </p>
    <p class="c19 c24">
      <span>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mengarahkan pointer mouse kearah kanan dan meng-klik symbol </span><span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 1.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 19.50px; height: 18.19px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image9.png')"
          style="width: 1563.93px; height: 649.66px; margin-left: -1481.21px; margin-top: -557.84px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span><span class="c4">&nbsp;atau titik-titik vertical. </span>
    </p>
    <p class="c19 c24">
      <span>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lalu mengklik dilanjutkan dengan meng-klik perintah </span><span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 1.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 63.65px; height: 25.05px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image9.png')"
          style="width: 856.00px; height: 355.13px; margin-left: -779.43px; margin-top: -277.54px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span><span class="c4">&nbsp;atau edit.</span>
    </p>
    <p class="c2 c24">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_7-2"
      start="2"
    >
      <li class="c14 li-bullet-1">
        <h3
          id="h.3dy6vkm"
          style="display:inline"
        >
          <span class="c1">Akses Menu Edit Melalui Halaman Detail Disposisi (Staff SE).</span>
        </h3>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c10">
      <span class="c4">Jika anda sedang berada pada halaman detail disposisi dan anda login sebagai Staff SE, maka anda bisa mengakses fitur edit disposisi dengan mengarahkan pointer mouse ke pojok kanan form disposisi lalu meng-klik nya, seperti yang ditunjukkan oleh kotak warna merah pada gambar dibawah ini.</span>
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 605.78px; height: 279.32px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image51.png')"
          style="width: 709.79px; height: 346.53px; margin-left: -104.01px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c10 c13">
      <span class="c4" />
    </p>
    <p class="c10 c13">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_7-2"
      start="3"
    >
      <li class="c14 li-bullet-1">
        <h3
          id="h.1t3h5sf"
          style="display:inline"
        >
          <span class="c1">Menyimpan Disposisi Setelah Melakukan Edit Data (Staff SE).</span>
        </h3>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c10">
      <span class="c4">Setelah user selesai untuk melakukan semua pengeditan data disposisi yang diperlukan, langkah selanjutnya yaitu melakukan penyimpanan data yang telah diubah tersebut, jika tidak dilakukan maka data yang telah diubah tidak akan tersimpan, berikut adalah cara menyimpan data yang telah di edit.</span>
    </p>
    <p class="c10 c13">
      <span class="c4" />
    </p>
    <p class="c19">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 740.40px; height: 390.47px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image53.png')"
          style="width: 740.40px; height: 390.47px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c19 c24">
      <span class="c4">Setelah data yang dibutuhkan telah di isi semua, langkah selanjutnya yaitu mengarahkan pointer mouse ke arah pojok kiri bawah dari seksi form disposisi seperti yang diberi tanda kotak berwarna merah diatas. </span>
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c2">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_7-1"
      start="5"
    >
      <li class="c14 li-bullet-2">
        <h2
          id="h.4d34og8"
          style="display:inline"
        >
          <span class="c1">Komentar Disposisi (Pihak Terkait Disposisi).</span>
        </h2>
      </li>
    </ol>
    <p class="c0">
      <span class="c11 c8" />
    </p>
    <p class="c26 c40">
      <span class="c4">Menu </span><span class="c11 c8">Komentar Disposisi</span><span class="c4">&nbsp;bisa diakses melalui halaman </span><span class="c11 c8">Detail Disposisi,</span><span class="c4">&nbsp;Untuk bisa menambahkan / update komentar pada </span><span class="c11 c8">Detail Disposisi</span><span class="c4">&nbsp;user harus memiliki akses sebagai pengambil </span><span class="c11 c8">Keputusan </span><span class="c4">atau sebagai </span><span class="c11 c8">Responder</span><span class="c4">, berikut contoh cara menambahkan komentar.</span>
    </p>
    <p class="c18 c13 c40">
      <span class="c4" />
    </p>
    <a id="t.3c9232b2d5d9ea2a259f8403ad80b64e9db35916" /><a id="t.4" />
    <table class="c23">
      <tr class="c25">
        <td
          class="c61"
          colspan="1"
          rowspan="1"
        >
          <p class="c18">
            <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 710.00px; height: 188.00px;">
              <b-img
                alt=""
                :src="require('@/assets/images/images-1/image55.png')"
                style="width: 710.00px; height: 188.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""
              />
            </span>
          </p>
        </td>
      </tr>
    </table>
    <p class="c18 c13">
      <span class="c4" />
    </p>
    <ul
      class="c6 lst-kix_list_9-0 start"
      start="1"
    >
      <li class="c26 c24 c55 li-bullet-0">
        <span class="c4">Langkah pertama yaitu mengisi komentar pada kolom komentar yang telah di sediakan seperti yang ditunjukkan pada kotak merah no.1 diatas.</span>
      </li>
      <li class="c26 c24 c55 li-bullet-0">
        <span class="c4">Setelah kolom komentar telah terisi, maka langkah selanjutnya yaitu mengklik perintah Update komentar yang ditunjukkan pada kotak merah no.2 diatas.</span>
      </li>
    </ul>
    <p class="c18 c13 c24">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_7-1"
      start="6"
    >
      <li class="c14 li-bullet-2">
        <h2
          id="h.2s8eyo1"
          style="display:inline"
        >
          <span class="c1">Ubah Status Disposisi - Open atau Close (Staff SE)</span>
        </h2>
      </li>
    </ol>
    <p class="c2 c12">
      <span class="c4" />
    </p>
    <p class="c19 c12">
      <span>Untuk mengubah status disposisi, kita bisa mengakses fitur tersebut kita harus mengaksesnya dari halaman </span><span class="c8">Detail Disposisi </span><span>atau </span><span class="c8">Edit Disposisi. </span><span>Pada halaman tersebut kita bisa menemukannya dengan melakukan scroll ke paling bawah halaman dan berada pada sisi kiri halaman tersebut, berikut gambar dibawah menjelaskan contoh mengubah status Disposisi dari </span><span class="c8">Open</span><span>&nbsp;menjadi </span><span class="c8">Close</span><span class="c4">.</span>
    </p>
    <p class="c2 c12">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 316.39px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image57.png')"
          style="width: 709.80px; height: 344.54px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 453.54px; height: 102.32px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image58.png')"
          style="width: 453.54px; height: 102.32px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 453.54px; height: 104.63px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image59.png')"
          style="width: 453.54px; height: 104.63px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 453.54px; height: 101.42px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image60.png')"
          style="width: 453.54px; height: 101.42px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <ul
      class="c6 lst-kix_list_10-0 start"
      start="1"
    >
      <li class="c28 li-bullet-0">
        <span class="c4">Setelah menemukan seksi atau bilah </span><span class="c11 c8">Status Disposisi,</span><span class="c4">&nbsp;hal pertma yang dilakukan adalah mengarahkan pointer mouse ke from status yang di tunjukkan pada kotak no.1, lalu klik.</span>
      </li>
      <li class="c28 li-bullet-0">
        <span class="c4">Selanjutnya akan tampil status disposisi yang bisa dipilih seperti yang ditunjukkan pada kotak no.2, maka kita pilih yang </span><span class="c11 c8">close</span><span class="c4">.</span>
      </li>
      <li class="c19 c55 c59 li-bullet-0">
        <span class="c4">Langkah terakhir yaitu menekan tombol simpan yang di tunjukkan pada kotak no.3.</span>
      </li>
    </ul>
    <hr style="page-break-before:always;display:none;">
    <p class="c2">
      <span class="c8 c33" />
    </p>
  </b-card>
</template>

<script>
import { BImg, BCard } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCard,
  },
}
</script>
